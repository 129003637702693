import Vue from 'vue';
import { Carousel, CarouselItem } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/css/font.css';
import scrollReveal from 'scrollreveal';
import 'swiper/dist/css/swiper.css';
import VueAwesomeSwiper from 'vue-awesome-swiper';
// 引入混合
import { hunhe } from './assets/js/mixin';

Vue.prototype.$scrollReveal = scrollReveal;

Vue.config.productionTip = false;

Vue.use(VueAwesomeSwiper);

// 使用混合
Vue.mixin(hunhe);

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

// 引入element组件
Vue.use(Carousel);
Vue.use(CarouselItem);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
