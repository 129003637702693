import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../views/Index.vue';

Vue.use(VueRouter);

const routes = [
  // 首页接口
  // {
  //   path: '/',
  //   redirect:'/Index'
  // },
  {
    path:'/',
    name: 'Index',
    component:Index
  },
  // 产品页面接口
  {
    path: '/message',
    name: 'Message',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/product/Message.vue'),
  },
  {
    path: '/messageServe',
    name: 'MessageServe',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/product/MessageServe.vue'
      ),
  },
  {
    path: '/number95',
    name: 'Number95',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/product/Number95.vue'),
  },
  {
    path: '/voice',
    name: 'Voice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/product/Voice.vue'),
  },
  {
    path: '/noticeSMS',
    name: 'NoticeSMS',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/product/NoticeSMS.vue'),
  },
  {
    path: '/memberSMS',
    name: 'MemberSMS',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/product/MemberSMS.vue'),
  },
  {
    path: '/videoSMS',
    name: 'VideoSMS',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/product/VideoSMS.vue'),
  },
  {
    path: '/serve95',
    name: 'Serve95',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/product/Serve95.vue'),
  },
  {
    path: '/fuseMessage',
    name: 'FuseMessage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/product/FuseMessage.vue'
      ),
  },
  {
    path: '/voiceCode',
    name: 'VoiceCode',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/product/VoiceCode.vue'),
  },
  {
    path: '/hideConnect',
    name: 'HideConnect',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/product/HideConnect.vue'
      ),
  },
  {
    path: '/callPlatform',
    name: 'CallPlatform',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/product/CallPlatform.vue'
      ),
  },
  {
    path: '/fixedCall',
    name: 'FixedCall',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/product/FixedCall.vue'),
  },
  {
    path: '/telephoneCard',
    name: 'TelephoneCard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/product/TelephoneCard.vue'
      ),
  },
  {
    path: '/wisdomMedia',
    name: 'WisdomMedia',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/product/WisdomMedia.vue'
      ),
  },
  {
    path: '/internationalCode',
    name: 'InternationalCode',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/product/InternationalCode.vue'
      ),
  },
  {
    path: '/internationalMarketingSms',
    name: 'internationalMarketingSms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/product/InternationalMarketingSms.vue'
      ),
  },
  {
    path: '/internationalSms',
    name: 'internationalSms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/product/InternationalSms.vue'
      ),
  },
  {
    path: '/5GMessagePlatform',
    name: '5GMessagePlatform',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/product/5GMessagePlatform.vue'
      ),
  },
  {
    path: '/5GMessage',
    name: '5GMessage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/product/5GMessage.vue'),
  },
  {
    path: '/message955G',
    name: 'Message955G',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/product/Message955G.vue'
      ),
  },
  {
    path: '/navigation',
    name: 'Navigation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/product/Navigation.vue'),
  },
  // 关于页面接口
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/about/About.vue'),
  },
  {
    path: '/honest',
    name: 'Honest',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/about/Honest.vue'),
  },
  {
    path: '/invite',
    name: 'Invite',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/about/Invite.vue'),
  },
  {
    path: '/oneclickLogin',
    name: 'OneclickLogin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/about/OneclickLogin.vue'
      ),
  },
  {
    path: '/join',
    name: 'Join',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/join/Join.vue'),
  },
  // 解决方案页面接口
  {
    path: '/governmentAndEnterprise',
    name: 'GovernmentAndEnterprise',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/solution/GovernmentAndEnterprise.vue'
      ),
  },
  {
    path: '/smallAndMediumsizedEnterprises',
    name: 'SmallAndMediumsizedEnterprises',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/solution/SmallAndMediumsizedEnterprises.vue'
      ),
  },
  {
    path: '/mobileInternet',
    name: 'MobileInternet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/solution/MobileInternet.vue'
      ),
  },
  {
    path: '/AI',
    name: 'AI',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/solution/AI.vue'),
  },
  {
    path: '/finance',
    name: 'Finance',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/solution/Finance.vue'),
  },
  {
    path: '/transportation',
    name: 'Transportation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/solution/Transportation.vue'
      ),
  },
  {
    path: '/ecommerce',
    name: 'Ecommerce',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/solution/Ecommerce.vue'),
  },
  {
    path: '/O2O',
    name: 'O2O',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/solution/O2O.vue'),
  },
];

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
