<template>
  <div class="index">
    <section class="part1 p1-swiper">
      <swiper :options="swiperOption" class="reveal-bottom2">
        <swiper-slide class="swiper-item" v-for="(item, i) in p1MData" :key="i">
          <div class="p1_item">
            <div class="p1_left">
              <span v-html="item.span"></span>
              <p v-html="item.p"></p>
              <div class="p1_lDown">
                <a
                  v-for="(item2, i2) in item.a"
                  :key="i2"
                  v-text="item2"
                  class="zhiCustomBtn"
                ></a>
              </div>
            </div>
            <img :class="item.imgClass" :src="item.img" alt="" />
          </div>
        </swiper-slide>
      </swiper>
      <div class="p1_down p1_down-1 reveal-bottom2">
        <router-link
          :to="item.link"
          class="p1_dItem"
          v-for="(item, i) in p1DData"
          :key="i"
        >
          <div>
            <img :src="item.img" alt="" />
          </div>
          <span v-text="item.span"></span>
        </router-link>
      </div>
      <div class="p1_down p1_down-2">
        <swiper :options="swiperOptionP1D">
          <swiper-slide
            class="swiper-item"
            v-for="(item, i) in p1DData"
            :key="i"
          >
            <router-link :to="item.link" class="p1_dItem">
              <div :class="item.imgClass">
                <img :src="item.img" alt="" />
              </div>
              <span v-text="item.span"></span>
            </router-link>
          </swiper-slide>
          <div
            class="swiper-pagination p1-pagination swiper_bg_white"
            slot="pagination"
          ></div>
        </swiper>
      </div>
    </section>
    <section class="part2">
      <span class="part-title">融合创新，互联网+企业通信专业化服务</span>
      <p class="part2-title">
        从企业通信辐射综合创新生态，资源整合、技术突破，助力企业加速前行
      </p>
      <div class="p2_bgMain reveal-bottom2">
        <div class="p2_main">
          <ul class="p2_left">
            <li
              class="p2_lItem"
              v-for="(item, i) in p2Data"
              :key="i"
              @click="p2Click(i)"
            >
              <div class="p2_lContent">
                <div class="p2_lImg">
                  <div>
                    <img :src="item.img" alt="" />
                  </div>
                  <span v-text="item.imgSpan"></span>
                </div>
                <div
                  class="p2_textContent"
                  v-for="(item2, i2) in item.content"
                  :key="i2"
                >
                  <span>{{ item2.span }}：</span>
                  <p v-html="item2.p"></p>
                </div>
              </div>
            </li>
          </ul>
          <div class="p2_right">
            <div
              class="p2_rItem"
              v-for="(item, i) in p2Data"
              :key="i"
              @click="p2Click(i)"
            >
              <span v-text="item.imgSpan"></span>
              <ul>
                <li v-for="(item2, i2) in item.content" :key="i2">
                  <router-link
                    :to="item2.link"
                    v-text="item2.span"
                  ></router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="p2_down">
        <router-link to="/">查看详情&nbsp;>&nbsp;</router-link>
        <router-link to="/">申请体验&nbsp;>&nbsp;</router-link>
      </div> -->
    </section>
    <section class="part3">
      <span class="part-title">精准定制，互联网+各行业个性化解决方案</span>
      <p class="part2-title reveal-bottom2">
        从互联网辐射至各个行业，专业服务、全面升级，实现行业定制通信方案
      </p>
      <div class="p3_main reveal-bottom2">
        <div class="p3_mUp">
          <div class="p3_mUp-line"></div>
          <div
            class="p3_uItem"
            v-for="(item, i) in p3Data"
            :key="i"
            @click="p3Click(item, i)"
          >
            <span v-text="item.span"></span>
          </div>
        </div>
        <div class="p3_mContent">
          <div class="p3_mCLeft">
            <span v-text="p3DataItem.topSpan"></span>
            <p v-html="p3DataItem.p"></p>
            <div>
              <span
                v-for="(item, i) in p3DataItem.bottomSpan"
                :key="i"
                v-text="item"
              ></span>
            </div>
            <router-link :to="p3DataItem.link">查看详情></router-link>
          </div>
          <div class="p3_mCRight">
            <img :src="p3DataItem.rightImg" alt="" />
          </div>
          <div class="p3_mDown">
            <img
              v-for="(item, i) in p3DataItem.iconImg.slice(0, 3)"
              :key="i"
              :src="item"
              alt=""
            />
          </div>
          <router-link :to="p3DataItem.link">查看详情></router-link>
        </div>
        <div class="p3_mDown">
          <img
            v-for="(item, i) in p3DataItem.iconImg"
            :key="i"
            :src="item"
            alt=""
          />
        </div>
      </div>
      <div class="p3_down">
        <div class="p3_dMain p3_dMain_pc">
          <div class="p3_dItem" v-for="(item, i) in p3DData" :key="i">
            <div>
              <img :src="item.img" alt="" />
            </div>
            <span v-text="item.span"></span>
          </div>
        </div>
        <div class="p3_dMain p3_dMain_mobile">
          <el-carousel
            trigger="click"
            :interval="5000"
            arrow="never"
            class="mobileImg"
          >
            <el-carousel-item
              class="swiper-item"
              v-for="(item, i) in p3DDatas"
              :key="i"
            >
              <div class="p3_dItem" v-for="(item2, i2) in item" :key="i2">
                <div>
                  <img :src="item2.img" alt="" />
                </div>
                <span v-text="item2.span"></span>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </section>
    <section class="part4">
      <span class="part-title">热门动态</span>
      <!-- <div class="p4_main reveal-bottom2">
        <a href="#" class="p4_left">
          <div class="p4_lUp">
            <img src="../assets/img/index/part4-icon.png" alt="" />
          </div>
          <div class="p4_lDown">
            <span>新跨越 新征程 | 集微科技厦门软件园投资</span>
            <p>
              近日，厦门市自然资源和规划局公布了一组厦门软件园三期C区的多组效果图以及综合服务规划。厦门集微科技有限公司（以下简称“集微科技”）作为北京百悟科技有限公司的全资子公司，将在厦门......
            </p>
            <div>
              <span>查看更多》</span>
              <p>2020-9-29</p>
            </div>
          </div>
        </a>
        <ul class="p4_right">
          <li>
            <a href="#">
              <span>新跨越 新征程 | 集微科技厦门软件园投资</span>
              <p>
                近日，厦门市自然资源和规划局公布了一组厦门软件园三期C区的多组效果图以及综合服务规划。厦门集微科技有限公司（以下简称“集微科技”）作为北京百悟科技有限公司的全资子公司，将在厦门......
              </p>
              <div>
                <span>查看更多》</span>
                <p>2020-9-29</p>
              </div>
            </a>
          </li>
          <li>
            <a href="#">
              <span>新跨越 新征程 | 集微科技厦门软件园投资</span>
              <p>
                近日，厦门市自然资源和规划局公布了一组厦门软件园三期C区的多组效果图以及综合服务规划。厦门集微科技有限公司（以下简称“集微科技”）作为北京百悟科技有限公司的全资子公司，将在厦门......
              </p>
              <div>
                <span>查看更多》</span>
                <p>2020-9-29</p>
              </div>
            </a>
          </li>
          <li>
            <a href="#">
              <span>新跨越 新征程 | 集微科技厦门软件园投资</span>
              <p>
                近日，厦门市自然资源和规划局公布了一组厦门软件园三期C区的多组效果图以及综合服务规划。厦门集微科技有限公司（以下简称“集微科技”）作为北京百悟科技有限公司的全资子公司，将在厦门......
              </p>
              <div>
                <span>查看更多》</span>
                <p>2020-9-29</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="p4_main reveal-bottom2">
        <a href="#" class="p4_left">
          <div class="p4_lUp">
            <img src="../assets/img/index/part4-icon.png" alt="" />
          </div>
          <div class="p4_lDown">
            <span>新跨越 新征程 | 集微科技厦门软件园投资</span>
            <p>
              近日，厦门市自然资源和规划局公布了一组厦门软件园三期C区的多组效果图以及综合服务规划。厦门集微科技有限公司（以下简称“集微科技”）作为北京百悟科技有限公司的全资子公司，将在厦门......
            </p>
            <div>
              <span>查看更多》</span>
              <p>2020-9-29</p>
            </div>
          </div>
        </a>
        <ul class="p4_right">
          <li>
            <a href="#">
              <span>新跨越 新征程 | 集微科技厦门软件园投资</span>
              <p>
                近日，厦门市自然资源和规划局公布了一组厦门软件园三期C区的多组效果图以及综合服务规划。厦门集微科技有限公司（以下简称“集微科技”）作为北京百悟科技有限公司的全资子公司，将在厦门......
              </p>
              <div>
                <span>查看更多》</span>
                <p>2020-9-29</p>
              </div>
            </a>
          </li>
          <li>
            <a href="#">
              <span>新跨越 新征程 | 集微科技厦门软件园投资</span>
              <p>
                近日，厦门市自然资源和规划局公布了一组厦门软件园三期C区的多组效果图以及综合服务规划。厦门集微科技有限公司（以下简称“集微科技”）作为北京百悟科技有限公司的全资子公司，将在厦门......
              </p>
              <div>
                <span>查看更多》</span>
                <p>2020-9-29</p>
              </div>
            </a>
          </li>
          <li>
            <a href="#">
              <span>新跨越 新征程 | 集微科技厦门软件园投资</span>
              <p>
                近日，厦门市自然资源和规划局公布了一组厦门软件园三期C区的多组效果图以及综合服务规划。厦门集微科技有限公司（以下简称“集微科技”）作为北京百悟科技有限公司的全资子公司，将在厦门......
              </p>
              <div>
                <span>查看更多》</span>
                <p>2020-9-29</p>
              </div>
            </a>
          </li>
        </ul>
      </div> -->
      <a href="news/news.html" class="p4_down"> 更多资讯 </a>
    </section>
    <section class="part5">
      <span class="part-title">关于百悟</span>
      <div class="reveal-bottom2">
        <p>
          北京百悟科技有限公司（以下简称“百悟科技”）成立于2010年，是全球化企业通信专业服务商，也是以5G、互联网、人工智能等高科技为驱动的国家高新技术企业。百悟科技包含北京百悟、香港百悟、新加坡百悟，北京百悟聚焦国内业务，主要为企业客户提供企业短信、企业语音客户服务热线（含95、1010、10195码号）、5G消息、云计算、人工智能等产品及技术支撑。香港百悟和新加坡百悟经营国际业务，为众多出海企业提供国际通讯解决方案。截至目前，百悟科技已经为移动互联网、金融、电商、医疗、交通运输等众多行业客户提供企业通信服务。
        </p>
        <p>
          百悟 · 万悟互联科技产业园是百悟科技全资子公司厦门集微科技有限公司投资建设的以元宇宙为主轴的科技+文化示范园区，总建筑面积约 3.45万㎡，总绿地面积近2500㎡，绿地率为20.01%。其目标是构建以生成式AI为核心引擎的元宇宙全产业链服务能力，围绕“人文科技融合，绿色生态环绕”的理念，打造成一个以智慧办公、科技研发、文化创意等整合式创新服务于一体的办公生活平台。
        </p>
        <p>
          百悟科技是中国三大电信运营商的密切合作伙伴，在企业通信的技术平台、产品服务、行业解决方案等多领域实现了深度合作，并积累了丰富的企业通信服务经验。同时，百悟科技还自主研发了企业通信应用“百悟云”、“百悟人工智能语义分析机器人”等自主知识产权产品，推动通信技术对各个行业的支撑发展。
        </p>
        <p>
          自成立以来，百悟科技始终以“上善若水，利万物而不争”为品牌价值导向，以专业的企业通信服务为众多企业客户提供完善的通信支撑。截至目前，百悟及其旗下全资子公司已为百度、京东、伊利、太平洋证券等诸多知名企业提供企业通信服务，业务覆盖区域广阔。
        </p>
        <p>
          凭借着过硬的技术实力和完善的产品服务，百悟科技获得了“OHSAS18001”职业健康安全管理体系认证、“ISO27000”信息安全管理体系认证、“ISO20000”信息技术服务管理体系认证等资质认可，“中国互联网领军企业”、“中国信息产业高成长性企业”、“互联网行业最佳解决方案商”等荣誉认可，标志着百悟科技的产品和服务在企业通信领域达到了国际认证的水平。
        </p>
        <p>
          随着中国经济发展进入到新阶段，5G、互联网+、物联网+、“一带一路”等战略方向加速了各行业的转型，百悟科技也加速了企业通信向“云大智物移”的迈进，不断以5G、互联网+、物联网+、云计算、人工智能等企业通信全生态链提升服务客户的竞争力，深度参与“一带一路”为核心源推动力的全球经济一体化、人类地球村万物互联时代的构建中。
        </p>
      </div>
      <router-link to="/about">查看更多</router-link>
      <!-- <div class="p5_video">
        <img src="../assets/img/index/part5-video.png" alt="" />
      </div> -->
    </section>
    <section class="part6">
      <span class="part-title">荣誉资质</span>
      <div class="part6_main indexPU6-swiper reveal-bottom2">
        <div class="part6_up">
          <span class="part6-title">企业荣誉</span>
          <swiper :options="swiperOptionP6U" class="p6_pc">
            <swiper-slide
              class="swiper-item"
              v-for="(item, i) in p6UData"
              :key="i"
            >
              <div class="p6-item">
                <div>
                  <img :src="item.img" alt="" />
                </div>
                <span>
                  <p v-text="item.span"></p>
                </span>
              </div>
            </swiper-slide>
          </swiper>
          <swiper :options="swiperOptionP6U2" class="p6_mobile">
            <swiper-slide
              class="swiper-item"
              v-for="(item, i) in p6UData"
              :key="i"
            >
              <div class="p6-item">
                <div>
                  <img :src="item.img" alt="" />
                </div>
                <span>
                  <p v-text="item.span"></p>
                </span>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="part6_down">
          <span class="part6-title">企业资质</span>
          <swiper :options="swiperOptionP6D" class="p6_pc">
            <swiper-slide
              class="swiper-item"
              v-for="(item, i) in p6DData"
              :key="i"
            >
              <div class="p6-item">
                <div>
                  <img :src="item.img" alt="" />
                </div>
                <span>
                  <p v-text="item.span"></p>
                </span>
              </div>
            </swiper-slide>
          </swiper>
          <swiper :options="swiperOptionP6D2" class="p6_mobile">
            <swiper-slide
              class="swiper-item"
              v-for="(item, i) in p6DData"
              :key="i"
            >
              <div class="p6-item">
                <div>
                  <img :src="item.img" alt="" />
                </div>
                <span>
                  <p v-text="item.span"></p>
                </span>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>
    <section class="part7" id="partNew">
      <img
        class="p7_img1 p7_img_pc"
        src="../assets/img/index/part7-bg.png"
        alt=""
      />
      <img
        class="p7_img2 p7_img_pc"
        src="../assets/img/index/part7-bg.png"
        alt=""
      />
      <img
        class="p7_img1 p7_img_mobile"
        src="../assets/img/index/mobile/part7-bg.png"
        alt=""
      />
      <img
        class="p7_img2 p7_img_mobile"
        src="../assets/img/index/mobile/part7-bg.png"
        alt=""
      />
      <div class="p7_main">
        <span>百悟及其全资子公司合作伙伴</span>
        <a class="zhiCustomBtn" style="cursor: pointer;">商务洽谈</a>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Index',
  created() {
    this.$nextTick(() => {
      this.toLocal();
    });
  },
  //用完后记得将存储的锚点置空，否则会影响其他页面跳转到当前页面
  destroyed() {
    window.sessionStorage.setItem('cec', '')
  },
  mounted() {
    // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    window.addEventListener("scroll", this.handleScroll);
    // p2初始化
    $('.p2_lItem').eq(0).addClass('active');
    $('.p2_rItem').eq(0).addClass('active');
    // p3初始化
    this.p3Click(this.p3DataItem, 0);
    // 初始化
    this.start();
    // 数据初始化
    this.p1DDatas = [
      JSON.parse(JSON.stringify(this.p1DData.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p1DData.slice(2, 4))),
      JSON.parse(JSON.stringify(this.p1DData.slice(-1))),
    ];
    this.p3DDatas = [
      JSON.parse(JSON.stringify(this.p3DData.slice(0, 3))),
      JSON.parse(JSON.stringify(this.p3DData.slice(-2))),
    ];
  },
  data() {
    return {
      // p1初始化
      p1MData: [
        {
          span: '创新5G机遇<br>全新数字生态',
          p: '深入5G通信探索与实践<br>激活数字创新力',
          a: ['商务合作'],
          img: require('../assets/img/index/part1-mIcon1.png'),
          imgClass: 'p1_bgImg1',
        },
        {
          span: '智慧引领<br>万物互联',
          p: '深化人与信息交互<br>构建互联网+时代的清明上河图',
          a: ['商务合作'],
          img: require('../assets/img/index/part1-mIcon2.png'),
          imgClass: 'p1_bgImg2',
        },
        {
          span: '跬步致远<br>方得始终',
          p: '十余载任重道远<br>开启一站式专业服务新篇',
          a: ['商务合作'],
          img: require('../assets/img/index/part1-mIcon3.png'),
          imgClass: 'p1_bgImg3',
        },
        {
          span: '品质保障<br>服务护航',
          p: '赋能企业降本增效<br>顺势而为实现精细化运营',
          a: ['商务合作'],
          img: require('../assets/img/index/part1-mIcon4.png'),
          imgClass: 'p1_bgImg4',
        },
      ],
      swiperOption: {
        autoplay: {
          delay: 5000, // 多长时间进行一次自动切换
          disableOnInteraction: false, // 用户点击一次后是否取消自动切换 默认true
        },
        loop: true,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      swiperOptionP1D: {
        pagination: {
          el: '.p1_down-2 .p1-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 2, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      p1DData: [
        {
          img: require('../assets/img/index/part1-dIcon1.png'),
          span: '5G消息',
          link: '/5GMessage',
          imgClass: 'p1_dImg1',
        },
        {
          img: require('../assets/img/index/part1-dIcon2.png'),
          span: '95语音',
          link: '/voice',
          imgClass: 'p1_dImg2',
        },
        {
          img: require('../assets/img/index/part1-dIcon3.png'),
          span: '国际短信',
          link: '/internationalSms',
          imgClass: 'p1_dImg3',
        },
        {
          img: require('../assets/img/index/part1-dIcon4.png'),
          span: '融合通信平台',
          link: '/fuseMessage',
          imgClass: 'p1_dImg4',
        },
        {
          img: require('../assets/img/index/part1-dIcon5.png'),
          span: '短信服务',
          link: '/messageServe',
          imgClass: 'p1_dImg5',
        },
      ],
      p1DDatas: [],
      // p2初始化
      p2Data: [
        {
          img: require('../assets/img/index/part2-icon1.png'),
          imgSpan: '消息云',
          content: [
            {
              span: '5G消息',
              p: '以企业chatbot为窗口，构建全新的消息生态',
              link: '/5GMessage',
            },
            {
              span: '会员短信',
              p: '个性发送、一主多备、高覆盖',
              link: '/memberSMS',
            },
            {
              span: '视频短信',
              p: '图片、动画、音频等信息内容，高效直达，即点即看',
              link: '/videoSMS',
            },
            {
              span: '通知短信',
              p: '快速稳定、海量并发、智能管理',
              link: '/noticeSMS',
            },
            {
              span: '智媒短信',
              p: '识别短信核心内容智能转换终端卡片消息，精准营销降本增效',
              link: '/wisdomMedia',
            },
          ],
        },
        {
          img: require('../assets/img/index/part2-icon2.png'),
          imgSpan: '语音',
          content: [
            {
              span: '电话名片',
              p: '号码与品牌有效关联，精准品牌曝光，防止误标记',
              link: '/telephoneCard',
            },
            {
              span: '固话外呼平台',
              p: '丰富的全国码号资源，强大的支撑并发处理能力',
              link: '/fixedCall',
            },
            {
              span: '呼叫中心平台',
              p: '拥有语音机器人、工单系统、知识库、智能外呼、在线客服、呼叫中心平台等多种功能',
              link: '/callPlatform',
            },
            {
              span: '隐私通话',
              p: '号源丰富，业务模式灵活、安全',
              link: '/hideConnect',
            },
            {
              span: '语音验证码',
              p: '安全稳定，多资源灾备，高品质语音质量',
              link: '/voiceCode',
            },
          ],
        },
        {
          img: require('../assets/img/index/part2-icon3.png'),
          imgSpan: '国际通讯',
          content: [
            {
              span: '国际短信',
              p: '为企业提供简单、安全、稳定的一站式通讯解决方案',
              link: '/internationalSms',
            },
            {
              span: '国际验证码短信',
              p: '多国语言，高效接入，秒级到达',
              link: '/internationalCode',
            },
            {
              span: '国际营销短信',
              p: '稳定的通道，快速的下达，优惠的资费',
              link: '/internationalMarketingSms',
            },
          ],
        },
        {
          img: require('../assets/img/index/part2-icon4.png'),
          imgSpan: '私有云',
          content: [
            {
              span: '5G消息平台',
              p: '提供Chatbot接入和管理服务，实现消息下发',
              link: '/5GMessagePlatform',
            },
            {
              span: '短信平台',
              p: '集企业短信智能路由、智能运营、系统管理、统计查询等功能的综合性企业通讯平台',
              link: '/message',
            },
            {
              span: '呼叫中心平台',
              p: '多种通信手段为客户提供交互式自助服务和人工服务',
              link: '/callPlatform',
            },
            {
              span: '融合通信平台',
              p: '更专业、全站式、一体化多场景解决方案',
              link: '/fuseMessage',
            },
          ],
        },
        {
          img: require('../assets/img/index/part2-icon5.png'),
          imgSpan: '95服务',
          content: [
            {
              span: '95码号服务',
              p: '隶属于工信部终身使用的特殊短号码，具有全国唯一、三网合一的特性',
              link: '/serve95',
            },
            {
              span: '95语音',
              p: '三网合一全国统一热线，企业“实力和信誉”必备名片',
              link: '/voice',
            },
            {
              span: '95 5G消息',
              p: '属于全网呼叫中心号码，全国统一的稀缺资源',
              link: '/message955G',
            },
          ],
        },
        {
          img: require('../assets/img/index/part2-icon6.png'),
          imgSpan: '一键登录',
          content: [
            {
              span: '一键登录',
              p: '用户无需谨记账号和密码，一键登录',
              link: '/oneclickLogin',
            },
          ],
        },
      ],
      // p3初始化
      p3Data: [
        {
          span: '电商行业',
          topSpan: '电商解决方案',
          p: '为电商企业提供安全，稳定的解决方案，帮助电商企业实现精准营销，促进客户增长。',
          bottomSpan: [
            '短信通知',
            '短信/语音验证码',
            '智能客服',
            '售后服务',
            '广告推送',
            '跨境信息沟通',
          ],
          link: '/ecommerce',
          rightImg: require('../assets/img/index/part3-item1/part3-rightImg.png'),
          iconImg: [
            require('../assets/img/index/part3-item1/part3-iconImg1.png'),
            require('../assets/img/index/part3-item1/part3-iconImg2.png'),
            require('../assets/img/index/part3-item1/part3-iconImg3.png'),
            require('../assets/img/index/part3-item1/part3-iconImg4.png'),
          ],
        },
        {
          span: 'O2O行业',
          topSpan: 'O2O解决方案',
          p: '针对各行业业务特性，打造个性化行业解决方案，为用户提供一站式的产品与服务，助力O2O系统搭建合理的生态体系。',
          bottomSpan: [
            '业务快速上云',
            '实时短信通知',
            '语音/短信验证码',
            '安全呼叫中心',
            '虚拟手机卡',
            '智能网络加速',
          ],
          link: '/O2O',
          rightImg: require('../assets/img/index/part3-item2/part3-rightImg.png'),
          iconImg: [
            require('../assets/img/index/part3-item2/part3-iconImg1.png'),
            require('../assets/img/index/part3-item2/part3-iconImg2.png'),
            require('../assets/img/index/part3-item2/part3-iconImg3.png'),
          ],
        },
        {
          span: '交通运输行业',
          topSpan: '交通运输解决方案',
          p: '提供数字化管理的共享服务平台，助力行业客户降低运营成本，提升服务效率。',
          bottomSpan: [
            '实时短信通知',
            '安全呼叫中心',
            'M2M通信',
            '设备状态管理',
            '车联网',
            '物流云',
          ],
          link: '/transportation',
          rightImg: require('../assets/img/index/part3-item3/part3-rightImg.png'),
          iconImg: [
            require('../assets/img/index/part3-item3/part3-iconImg1.png'),
            require('../assets/img/index/part3-item3/part3-iconImg2.png'),
            require('../assets/img/index/part3-item3/part3-iconImg3.png'),
          ],
        },
        {
          span: '移动互联网行业',
          topSpan: '移动互联网解决方案',
          p: '百悟科技为移动APP应用提供基础通信及网络支撑，为用户实现移动通信。移动安全、移动加速等应用服务，满足业务发展需求，助力构建移动互联生态。',
          bottomSpan: [
            '实时消息推送',
            '静态/动态加速',
            '业务快速上云',
            '广告推送',
            '企业客户服务热线',
            '海量信息存储',
          ],
          link: '/mobileInternet',
          rightImg: require('../assets/img/index/part3-item4/part3-rightImg.png'),
          iconImg: [
            require('../assets/img/index/part3-item4/part3-iconImg1.png'),
            require('../assets/img/index/part3-item4/part3-iconImg2.png'),
            require('../assets/img/index/part3-item4/part3-iconImg3.png'),
            require('../assets/img/index/part3-item4/part3-iconImg4.png'),
          ],
        },
        {
          span: '金融行业',
          topSpan: '金融解决方案',
          p: '百悟科技为互联网金融、银行、证券等行业提供包括安全、合规等在内的完备产品和服务，助力金融机构实现云端部署、进行业务创新。',
          bottomSpan: [
            '企业客服服务热线',
            '加密保障',
            '私有云-软件授权',
            '语音/短信验证码',
            '广告推送',
            '智能金融服务',
          ],
          link: '/finance',
          rightImg: require('../assets/img/index/part3-item5/part3-rightImg.png'),
          iconImg: [
            require('../assets/img/index/part3-item5/part3-iconImg1.png'),
            require('../assets/img/index/part3-item5/part3-iconImg2.png'),
            require('../assets/img/index/part3-item5/part3-iconImg3.png'),
            require('../assets/img/index/part3-item5/part3-iconImg4.png'),
            require('../assets/img/index/part3-item5/part3-iconImg5.png'),
          ],
        },
        {
          span: '政企部门',
          topSpan: '政企解决方案',
          p: '百悟科技为政企类客户提供云计算/物联网和移动通讯技术的政企信息化整体解决方案。实现政企服务的互联网化/智能化和集约化，打造高效的智慧政企。',
          bottomSpan: [
            '统一通信平台',
            '政务云',
            '云桌面',
            '网评系统',
            '互联网+政务服务',
            '智慧校园',
          ],
          link: '/governmentAndEnterprise',
          rightImg: require('../assets/img/index/part3-item6/part3-rightImg.png'),
          iconImg: [],
        },
        {
          span: '中小企业通信',
          topSpan: '中小企业通信解决方案',
          p: '综合百悟完善的企业通信生态体系，集成多品牌客户多产品服务经验，百悟科技为中小企业客户提供平台式的自助式企业通信解决方案，实现一键接入，便捷服务！',
          bottomSpan: ['平台自助式', '业务集成化', '产品多样性', '服务稳定性'],
          link: '/smallAndMediumsizedEnterprises',
          rightImg: require('../assets/img/index/part3-item7/part3-rightImg.png'),
          iconImg: [],
        },
      ],
      p3DataItem: {
        span: '电商行业',
        topSpan: '电商解决方案',
        p: '为电商企业提供安全，稳定的解决方案，帮助电商企业实现精准营销，促进客户增长。',
        bottomSpan: [
          '短信通知',
          '短信/语音验证码',
          '智能客服',
          '售后服务',
          '广告推送',
          '跨境信息沟通',
        ],
        link: '/ecommerce',
        rightImg: require('../assets/img/index/part3-item1/part3-rightImg.png'),
        iconImg: [
          require('../assets/img/index/part3-item1/part3-iconImg1.png'),
          require('../assets/img/index/part3-item1/part3-iconImg2.png'),
          require('../assets/img/index/part3-item1/part3-iconImg3.png'),
          require('../assets/img/index/part3-item1/part3-iconImg4.png'),
        ],
      },
      p3DData: [
        {
          img: require('../assets/img/index/part3-dIcon1.png'),
          span: '1V1大客户服务',
        },
        {
          img: require('../assets/img/index/part3-dIcon2.png'),
          span: '7*24小时技术咨询',
        },
        {
          img: require('../assets/img/index/part3-dIcon3.png'),
          span: '秒级故障响应',
        },
        {
          img: require('../assets/img/index/part3-dIcon4.png'),
          span: '免费咨询与备案',
        },
        {
          img: require('../assets/img/index/part3-dIcon5.png'),
          span: '反馈与服务建议',
        },
      ],
      swiperOptionP3Down: {
        pagination: {
          el: '.p3_dMain_mobile .swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      p3DDatas: [],
      // p6初始化
      swiperOptionP6U: {
        autoplay: {
          delay: 3500, // 多长时间进行一次自动切换
          disableOnInteraction: false, // 用户点击一次后是否取消自动切换 默认true
        },
        loop: true,
        slidesPerView: 3, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      swiperOptionP6U2: {
        autoplay: {
          delay: 3500, // 多长时间进行一次自动切换
          disableOnInteraction: false, // 用户点击一次后是否取消自动切换 默认true
        },
        loop: true,
        slidesPerView: 2, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      swiperOptionP6D: {
        autoplay: {
          delay: 3500, // 多长时间进行一次自动切换
          disableOnInteraction: false, // 用户点击一次后是否取消自动切换 默认true
        },
        loop: true,
        slidesPerView: 3, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      swiperOptionP6D2: {
        autoplay: {
          delay: 3500, // 多长时间进行一次自动切换
          disableOnInteraction: false, // 用户点击一次后是否取消自动切换 默认true
        },
        loop: true,
        slidesPerView: 2, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      p6UData: [
        {
          img: require('../assets/img/about/about/part4-uIcon1.png'),
          span: '2021年中国5G实力榜5G消息企业十强',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon2.png'),
          span: '诚信兴商宣传月-倡议企业',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon3.png'),
          span: '2021主动亮信企业',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon4.png'),
          span: '北京市诚信创建企业',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon5.png'),
          span: '北京市企业创新信用领跑企业',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon6.png'),
          span: '北京市专精特新小巨人企业证书',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon7.png'),
          span: '北京市专精特新中小企业证书',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon8.png'),
          span: '第四届绽放杯5G应用征集大赛5G消息专题赛行业特色奖',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon22.png'),
          span: '第五届“绽放杯”三等奖',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon23.png'),
          span: '第五届“绽放杯”特色奖',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon9.png'),
          span: '电子商务百强企业',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon10.png'),
          span: '互联网行业最佳解决方案奖',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon11.png'),
          span: '互联网行业最具影响力企业奖',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon12.png'),
          span: '神州英才共建OS系统培训基地',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon13.png'),
          span: '神州英才全球创新管理EDP实践基地',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon14.png'),
          span: '移动运营商年度最佳合作伙伴',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon15.png'),
          span: '优秀软件产品',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon16.png'),
          span: '增值业务最佳市场开拓奖',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon17.png'),
          span: '中国互联网公益奖',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon18.png'),
          span: '中国互联网领军企业',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon19.png'),
          span: '中国新媒体无线营销之星',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon20.png'),
          span: '中国信息产业高成长性企业',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon21.png'),
          span: '中国移动2021年度集团短彩信业务金牌合作伙伴',
        },
      ],
      p6DData: [
        {
          img: require('../assets/img/about/about/part4-dIcon1.png'),
          span: '2021中关村高新企业新证书',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon2.png'),
          span: '北京市诚信创建企业',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon3.png'),
          span: '2020中国互联网协会会员单位',
        },
        {
          img: require('../assets/img/about/about/part4-uIcon6.png'),
          span: '北京市专精特新小巨人企业证书',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon4.png'),
          span: '“ISO9001”质量管理体系认证',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon5.png'),
          span: 'ISO20000-信息技术服务管理体系认证',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon6.png'),
          span: 'ISO27001-信息安全管理体系认证',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon7.png'),
          span: '2019高新证书',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon8.png'),
          span: '2021北京百悟5G消息工作组理事单位',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon9.png'),
          span: '2021通信工作组成员单位',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon10.png'),
          span: '北京市用户满意评价证书',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon11.png'),
          span: '第四届常务委员会常务单位',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon12.png'),
          span: '副主任单位',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon13.png'),
          span: '国家高新技术企业证书',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon14.png'),
          span: '企业综合信用评价等级证书-百悟',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon15.png'),
          span: '信用企业承诺',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon16.png'),
          span: '知识产权试点单位',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon18.png'),
          span: '中国互联网协会会员',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon19.png'),
          span: '中国互联网协会理事单位及协会理事（傅竞捷）',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon20.png'),
          span: '中国互联网协会数字孪生技术应用工作委员会-成员单位证书',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon21.png'),
          span: '中国通信协会会员',
        },
        {
          img: require('../assets/img/about/about/part4-dIcon22.png'),
          span: 'AAA级信用企业',
        },
      ],
    };
  },
  methods: {
    // 滑动
    toLocal() {
      //查找存储的锚点id
      let Id = window.sessionStorage.getItem("cec");
      let toElement = document.getElementById(Id);
      //锚点存在跳转
      if (Id) {
        toElement.scrollIntoView();
      }
    },
    // p2点击事件
    p2Click(i) {
      $('.p2_lItem').removeClass('active');
      $('.p2_lItem').eq(i).addClass('active');
      $('.p2_rItem').removeClass('active');
      $('.p2_rItem').eq(i).addClass('active');
    },
    // p3点击事件
    p3Click(item, i) {
      // 字体设置
      $('.p3_uItem').removeClass('active');
      $('.p3_uItem').eq(i).addClass('active');
      // 数据重新设置
      this.p3DataItem = item;
      if ($(window).width() > 768) {
        // 线长与位置
        $('.p3_mUp-line').width($('.p3_uItem').eq(i).width() + 20);
        $('.p3_mUp-line').animate(
          { left: $('.p3_uItem').eq(i).position().left },
          300
        );
        switch (this.p3DataItem.bottomSpan.length) {
          case 4:
            $('.p3_mContent .p3_mCLeft > div').css('width', '132%');
            break;
          case 6:
            $('.p3_mContent .p3_mCLeft > div').css('width', '100%');
            break;
          default:
            $('.p3_mContent .p3_mCLeft > div').css('width', '100%');
            break;
        }
      }
    },
    // 监控页面初始化
    start() {},
  },
};
</script>
<style lang="less" scoped>
.index {
  .part1 {
    width: 100%;
    height: 700px;
    background: url('../assets/img/index/part1-bg.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    .p1_item {
      position: relative;
      .p1_left {
        display: flex;
        flex-direction: column;
        padding-top: 149px;
        span {
          font-size: 48px;
          font-weight: bold;
          color: #333333;
          line-height: 72px;
          margin-bottom: 29px;
        }
        p {
          font-size: 24px;
          font-weight: 400;
          color: #989898;
          line-height: 40px;
          margin-bottom: 70px;
        }
        .p1_lDown {
          display: flex;
          flex-direction: row;
          a {
            width: 139px;
            height: 43px;
            border-radius: 22px;
            font-size: 18px;
            font-weight: 400;
            line-height: 43px;
            text-align: center;
            margin-right: 22px;
            background: #e4eef9;
            color: #005cb9;
          }
          a:first-child {
            background: #005cb9;
            color: #fbfbfd;
          }
        }
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }
      .p1_bgImg1 {
        left: 302px;
      }
      .p1_bgImg2 {
        left: 196px;
      }
      .p1_bgImg3 {
        left: 400px;
      }
      .p1_bgImg4 {
        left: 285px;
      }
    }
    .p1_down {
      z-index: 2;
      width: 1200px;
      position: absolute;
      bottom: 44px;
      left: calc(50% - 600px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .p1_dItem {
        box-sizing: border-box;
        display: flex;
        width: 230px;
        height: 100px;
        background: rgba(255, 255, 255, 0.97);
        border: 1px solid #dfdfdf;
        border-radius: 5px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        transition: margin 0.5s;
        div {
          width: 111px;
          height: 92px;
          margin-right: 15px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        span {
          font-size: 18px;
          font-family: FontExcellent;
          font-weight: 400;
          color: #005cb9;
        }
      }
      .p1_dItem:nth-of-type(2) {
        div {
          margin-top: 10px;
        }
      }
      .p1_dItem:nth-of-type(3) {
        div {
          margin-top: -10px;
        }
      }
      .p1_dItem:nth-of-type(4) {
        div {
          margin-top: 20px;
          margin-right: 0px;
        }
      }
      .p1_dItem:hover {
        border: 0px;
        box-shadow: 4px 7px 15px 1px rgba(38, 113, 209, 0.14);
        margin-top: -19px;
      }
    }
    .p1_down-2 {
      display: none;
    }
  }
  .part2 {
    padding-top: 92px;
    .part-title {
      margin-bottom: 31px;
    }
    .part2-title {
      font-size: 24px;
      font-weight: 400;
      color: #666666;
      text-align: center;
      margin-bottom: 72px;
    }
    .p2_bgMain {
      width: 100%;
      background: url('../assets/img/index/part2-bg.png') no-repeat;
      background-size: 100% 100%;
      .p2_main {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .p2_left {
          width: 950px;
          margin-left: -27px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;
          .p2_lItem {
            box-sizing: border-box;
            width: 475px;
            border-right: 1px solid #eaeaea;
            border-bottom: 1px solid #eaeaea;
            .p2_lContent {
              max-width: 436px;
              min-height: 296px;
              margin: 4px 9px 4px 27px;
              padding-top: 19px;
              padding-left: 27px;
              padding-bottom: 17px;
              .p2_lImg {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 23px;
                div {
                  width: 58px;
                  height: 56px;
                  margin-right: 14px;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                }
                span {
                  font-size: 24px;
                  font-family: FontExcellent;
                  font-weight: 400;
                  color: #333333;
                }
              }
              .p2_textContent {
                width: 370px;
                span,
                p {
                  font-size: 16px;
                  font-weight: 400;
                  color: #666666;
                  text-align: justify;
                }
                span {
                  font-size: 18px;
                  font-family: FontExcellent;
                  color: #005cb9;
                  margin-bottom: 5px;
                  display: block;
                }
                p {
                  line-height: 26px;
                  margin-bottom: 4px;
                }
              }
            }
          }
          .p2_lItem:nth-of-type(2n) {
            border-right: 0px;
            .p2_lContent {
              margin-right: 27px;
              margin-left: 9px;
            }
          }
          .p2_lItem:nth-of-type(5),
          .p2_lItem:nth-of-type(6) {
            border-bottom: 0px;
          }
          .p2_lItem.active {
            .p2_lContent {
              background: rgba(235, 242, 251, 0.41);
              .p2_lImg {
                span {
                  color: #005cb9;
                }
              }
            }
          }
        }
        .p2_right {
          width: 264px;
          height: min-content;
          box-shadow: 11.5px 13.8px 15.2px 0.8px rgba(176, 176, 176, 0.14);
          z-index: 2;
          .p2_rItem {
            width: 100%;
            height: 67px;
            background: rgba(235, 242, 251, 0.41);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-top: 1px solid #dfdfdf;
            transition: height 1s;
            span {
              font-size: 24px;
              font-family: FontExcellent;
              font-weight: 400;
              color: #005cb9;
            }
            > ul {
              display: none;
            }
            > a {
              display: none;
            }
          }
          .p2_rItem:first-child {
            border-top: 0px solid #dfdfdf;
          }
          .p2_rItem.active {
            width: 264px;
            height: 333px;
            background: url('../assets/img/index/part2-rBg.png') no-repeat;
            background-size: 100% 100%;
            span {
              color: #ffffff;
              margin-bottom: 17px;
            }
            > ul {
              min-height: 130px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-bottom: 25px;
              li {
                text-align: center;
                a {
                  font-size: 16px;
                  font-weight: 400;
                  color: #ffffff;
                  line-height: 26px;
                }
              }
            }
            > a {
              display: block;
              width: 122px;
              height: 39px;
              background: #ffffff;
              border-radius: 3px;
              font-size: 18px;
              font-family: FontExcellent;
              font-weight: 400;
              color: #005cb9;
              line-height: 39px;
              text-align: center;
            }
          }
        }
      }
    }
    .p2_down {
      width: 1148px;
      margin: 0 auto;
      margin-top: 41px;
      margin-bottom: 43px;
      a {
        font-size: 18px;
        font-weight: 400;
        font-family: FontExcellent;
        color: #666666;
      }
      a:last-child {
        color: #005cb9;
      }
    }
  }
  .part3 {
    width: 100%;
    background: url('../assets/img/index/part3-bg.png') no-repeat;
    background-size: 100% 100%;
    padding-top: 88px;
    z-index: 0;
    .part-title {
      margin-bottom: 31px;
      color: #ffffff;
    }
    .part2-title {
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      margin-bottom: 72px;
    }
    .p3_main {
      width: 1129px;
      height: 523px;
      background: #ffffff;
      border-radius: 11px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      z-index: 2;
      position: relative;
      .p3_mUp {
        box-sizing: border-box;
        width: 100%;
        height: 77px;
        border-bottom: 1px solid #d7d7d7;
        padding: 0px 66px;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .p3_mUp-line {
          width: 94px;
          height: 2px;
          background: #005cb9;
          position: absolute;
          bottom: 0px;
          left: 66px;
        }
        .p3_uItem {
          padding: 0px 10px;
          cursor: pointer;
          span {
            font-size: 18px;
            font-weight: bold;
            color: #10151c;
            line-height: 35px;
          }
        }
        .p3_uItem.active {
          span {
            color: #005cb9;
          }
        }
      }
      .p3_mContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 77px;
        margin-right: 61px;
        .p3_mCLeft {
          width: 402px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-top: 43px;
          > span {
            display: block;
            font-size: 24px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #10151c;
          }
          > p {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 27px;
            text-align: justify;
          }
          > div {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            span {
              display: block;
              width: 123px;
              height: 37px;
              background: linear-gradient(-21deg, #0c63bb 0%, #6392cd 100%);
              border-radius: 4px;
              text-align: center;
              line-height: 37px;
              font-size: 16px;
              font-family: FontExcellent;
              font-weight: 400;
              color: #ffffff;
              margin-bottom: 16px;
            }
            span:nth-of-type(4),
            span:nth-of-type(5),
            span:nth-of-type(6) {
              margin-bottom: 0px;
            }
          }
          > a {
            font-size: 18px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #005cb9;
            display: block;
            margin-bottom: 18px;
          }
        }
        .p3_mCRight {
          padding-top: 10px;
          img {
            width: 423px;
            height: 324px;
          }
        }
        > .p3_mDown {
          display: none;
        }
        > a {
          display: none;
        }
      }
      .p3_mDown {
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: row;
        border-top: 1px solid #d7d7d7;
        padding-top: 21px;
        padding-left: 67px;
        img {
          width: 173px;
          height: 80px;
          margin-right: 27px;
        }
      }
    }
    .p3_down {
      box-sizing: border-box;
      width: 100%;
      height: 183px;
      background: linear-gradient(-15deg, #101820 0%, #47596c 100%);
      margin-top: -54px;
      padding-top: 110px;
      z-index: 1;
      .p3_dMain {
        width: 1200px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 auto;
        .p3_dItem {
          display: flex;
          flex-direction: row;
          align-items: center;
          div {
            width: 25px;
            height: 25px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-right: 9px;
          }
          span {
            font-size: 20px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
      .p3_dMain_mobile {
        display: none;
      }
    }
  }
  .part4 {
    width: 100%;
    padding-top: 78px;
    .part-title {
      margin-bottom: 66px;
    }
    .p4_main {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .p4_left {
        width: 571px;
        height: 583px;
        border: 1px solid #dbdbdb;
        box-sizing: border-box;
        padding-top: 35px;
        .p4_lUp {
          width: 100%;
          height: 318px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .p4_lDown {
          width: 100%;
          padding: 0px 37px;
          padding-top: 28px;
          > span {
            font-size: 24px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #333333;
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            margin-bottom: 28px;
          }
          > p {
            width: 497px;
            font-size: 16px;
            line-height: 27px;
            font-weight: 400;
            color: #666666;
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            text-align: justify;
            margin-bottom: 17px;
          }
          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 497px;
            span {
              font-size: 16px;
              font-family: FontExcellent;
              font-weight: 400;
              color: #0b62bb;
            }
            p {
              font-size: 16px;
              font-weight: 400;
              color: #666666;
            }
          }
        }
      }
      .p4_right {
        width: 593px;
        display: flex;
        flex-direction: column;
        li {
          flex: 1;
          border-top: 1px solid #dbdbdb;
          padding-left: 68px;
          padding-top: 13px;
          position: relative;
          a {
            > span {
              font-size: 24px;
              font-family: FontExcellent;
              font-weight: 400;
              color: #333333;
              display: -webkit-box;
              text-overflow: ellipsis;
              overflow: hidden;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              margin-bottom: 11px;
            }
            > p {
              font-size: 16px;
              font-weight: 400;
              color: #666666;
              display: -webkit-box;
              text-overflow: ellipsis;
              overflow: hidden;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              line-height: 27px;
              width: 497px;
              margin-bottom: 13px;
            }
            > div {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 497px;
              span {
                font-size: 16px;
                font-family: FontExcellent;
                font-weight: 400;
                color: #333333;
              }
              p {
                font-size: 16px;
                font-weight: 400;
                color: #666666;
              }
            }
          }
        }
        li::after {
          position: absolute;
          top: 21px;
          left: 31px;
          display: block;
          content: '';
          width: 14px;
          height: 14px;
          background: #333333;
          border-radius: 50%;
        }
        li:last-child {
          border-bottom: 1px solid #dbdbdb;
        }
        li:hover {
          a {
            > span,
            > div span {
              color: #0b62bb;
            }
          }
        }
        li:hover::after {
          background: #0b62bb;
        }
      }
    }
    .p4_main:nth-of-type(2) {
      display: none;
    }
    .p4_down {
      display: block;
      width: 204px;
      margin: 0 auto;
      height: 47px;
      background: #0b62bb;
      border-radius: 7px;
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 47px;
      margin-top: 89px;
    }
  }
  .part5 {
    width: 100%;
    padding-top: 163px;
    .part-title {
      margin-bottom: 44px;
    }
    p {
      display: block;
      width: 1112px;
      margin: 0 auto;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      text-align: justify;
      margin-bottom: 30px;
    }
    > a {
      display: none;
    }
    .p5_video {
      width: min-content;
      margin: 0 auto;
      margin-top: 45px;
    }
  }
  .part6 {
    padding-top: 83px;
    .part-title {
      margin-bottom: 58px;
    }
    .part6_main {
      width: 1200px;
      margin: 0 auto;
      .part6-title {
        display: block;
        font-size: 24px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #666666;
        text-align: center;
        margin-bottom: 34px;
      }
      .swiper-item {
        height: 400px;
        .p6-item {
          width: 373px;
          height: 400px;
          margin: 0 auto;
          position: relative;
          background: linear-gradient(
            -38deg,
            #ffffff 0%,
            #f2f4fa 52%,
            #ffffff 100%
          );
          > div {
            width: 100%;
            height: 340px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            img {
              z-index: 3;
            }
          }
          span {
            z-index: 2;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('../assets/img/about/about/part4-item-bg.png')
              no-repeat;
            background-size: 100% 100%;
            text-align: end;
            p {
              box-sizing: border-box;
              width: 100%;
              height: 65px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              text-align: center;
              padding: 0px 19px;
              font-size: 18px;
              font-family: FontExcellent;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
      }
      .part6_up {
        margin-bottom: 48px;
      }
      .part6_down {
        margin-bottom: 61px;
      }
    }
    .p6_mobile {
      display: none;
    }
  }
  .part7 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 686px;
    position: relative;
    margin-bottom: -1px;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-duration: 15s;
    }
    .p7_img_mobile {
      display: none;
    }
    .p7_img1 {
      animation-name: roll1;
    }
    .p7_img2 {
      animation-name: roll2;
    }
    @keyframes roll1 {
      0% {
        left: 0px;
        top: 0px;
      }
      100% {
        left: 100%;
        top: 0px;
      }
    }
    @keyframes roll2 {
      0% {
        left: -100%;
        top: 0px;
      }
      100% {
        left: 0px;
        top: 0px;
      }
    }
    .p7_main {
      z-index: 5;
      span {
        display: block;
        font-size: 36px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #ffffff;
        text-align: justify;
        letter-spacing: 0.3px;
        margin-bottom: 27px;
      }
      a {
        display: block;
        width: 268px;
        height: 64px;
        border: 3px solid #ffffff;
        background: linear-gradient(-33deg, #316ebc 0%, #005cb9 100%);
        border-radius: 4px;
        font-size: 30px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 64px;
        margin: 0 auto;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .part1 {
      height: 4.12rem;
      background: url('../assets/img/index/part1-bg.png') no-repeat;
      background-size: 100% 100%;
      .p1_item {
        .p1_left {
          padding-top: 0.96rem;
          span {
            font-size: 0.33rem;
            line-height: 0.5rem;
            margin-bottom: 0.14rem;
          }
          p {
            font-size: 0.19rem;
            line-height: 0.35rem;
            margin-bottom: 0.35rem;
          }
          .p1_lDown {
            a {
              width: 1.39rem;
              height: 0.43rem;
              border-radius: 0.22rem;
              font-size: 0.18rem;
              line-height: 0.43rem;
              margin-right: 0.22rem;
            }
          }
        }
        img {
          position: absolute;
          top: 0;
          left: auto;
          z-index: -1;
          right: -0.7rem;
        }
        .p1_bgImg1 {
          content: url('../assets/img/index/mobile/part1-mIcon1.png');
          width: 5.02rem;
          height: 3.25rem;
          left: auto;
        }
        .p1_bgImg2 {
          content: url('../assets/img/index/mobile/part1-mIcon2.png');
          width: 4.61rem;
          height: 3.69rem;
          left: auto;
        }
        .p1_bgImg3 {
          content: url('../assets/img/index/mobile/part1-mIcon3.png');
          width: 4.76rem;
          height: 4.75rem;
          left: auto;
        }
        .p1_bgImg4 {
          content: url('../assets/img/index/mobile/part1-mIcon4.png');
          width: 5.34rem;
          height: 4.5rem;
          left: auto;
        }
      }
      .p1_down {
        width: 5.03rem;
        margin: 0 auto;
        .swiper-item {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
        .p1_dItem {
          width: 2.3rem;
          height: 1rem;
          border-radius: 0.05rem;
          div {
            width: 1.11rem;
            height: 0.92rem;
            margin-right: 0.15rem;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          span {
            font-size: 0.18rem;
          }
        }
        .p1_dImg2 {
          margin-top: 0.1rem !important;
        }
        .p1_dImg3 {
          margin-top: -0.1rem !important;
        }
        .p1_dImg4 {
          margin-top: 0.2rem !important;
          margin-right: 0rem !important;
        }
        .p1_dItem:hover {
          border: 1px solid #dfdfdf;
          box-shadow: 4px 7px 15px 1px rgba(38, 113, 209, 0);
          margin-top: 0px;
        }
      }
      .p1_down-1 {
        display: none;
      }
      .p1_down-2 {
        display: block;
        position: relative;
        bottom: -0.18rem;
        left: 0px;
      }
    }
    .part2 {
      width: 100%;
      height: 7.41rem;
      padding-top: 0rem;
      margin-top: 2.21rem;
      background: url('../assets/img/index/mobile/part2-bg.png') no-repeat;
      background-size: 100% 100%;
      .part-title {
        margin-bottom: 0.31rem;
      }
      .part2-title {
        font-size: 0.19rem;
        margin-bottom: 0.61rem;
      }
      .p2_bgMain {
        width: 100%;
        background: none;
        .p2_main {
          width: 6.14rem;
          .p2_left {
            width: 3.54rem;
            min-height: 4.74rem;
            background: rgba(235, 242, 251, 0.41);
            margin-left: 0px;
            display: block;
            .p2_lItem {
              width: 3.54rem;
              min-height: 4.74rem;
              border-right: 0px solid #eaeaea;
              border-bottom: 0px solid #eaeaea;
              display: none;
              .p2_lContent {
                max-width: 3.54rem;
                min-height: 4.74rem;
                margin: 0px;
                padding-top: 0.21rem;
                padding-left: 0.27rem;
                padding-bottom: 0rem;
                .p2_lImg {
                  margin-bottom: 0.23rem;
                  div {
                    width: 0.58rem;
                    height: 0.56rem;
                    margin-right: 0.08rem;
                    img {
                      max-width: 0.58rem;
                      max-height: 0.56rem;
                    }
                  }
                  span {
                    font-size: 0.21rem;
                  }
                }
                .p2_textContent {
                  width: 2.86rem;
                  span,
                  p {
                    font-size: 0.17rem;
                  }
                  span {
                    font-size: 0.19rem;
                    margin-bottom: 0.05rem;
                  }
                  p {
                    line-height: 0.26rem;
                    margin-bottom: 0.04rem;
                  }
                }
              }
            }
            .p2_lItem:nth-of-type(2n) {
              border-right: 0px;
              .p2_lContent {
                margin-right: 0px;
                margin-left: 0px;
              }
            }
            .p2_lItem:nth-of-type(5),
            .p2_lItem:nth-of-type(6) {
              border-bottom: 0px;
            }
            .p2_lItem.active {
              display: block;
              .p2_lContent {
                background: rgba(235, 242, 251, 0.41);
                .p2_lImg {
                  span {
                    color: #005cb9;
                  }
                }
              }
            }
          }
          .p2_right {
            width: 2.64rem;
            box-shadow: 0.115rem 0.138rem 0.152rem 0.008rem
              rgba(176, 176, 176, 0.14);
            .p2_rItem {
              height: 0.43rem;
              span {
                font-size: 0.21rem;
              }
            }
            .p2_rItem.active {
              width: 2.64rem;
              height: 2.74rem;
              background: url('../assets/img/index/part2-rBg.png') no-repeat;
              background-size: 100% 100%;
              span {
                color: #ffffff;
                margin-bottom: 0.1rem;
              }
              > ul {
                min-height: 1.3rem;
                margin-bottom: 0.1rem;
                li {
                  a {
                    font-size: 0.17rem;
                    line-height: 0.26rem;
                  }
                }
              }
              > a {
                width: 1.22rem;
                height: 0.39rem;
                border-radius: 0.03rem;
                font-size: 0.17rem;
                line-height: 0.39rem;
              }
            }
          }
        }
      }
      .p2_down {
        display: none;
      }
    }
    .part3 {
      width: 100%;
      background: url('../assets/img/index/mobile/part3-bg.png') no-repeat;
      background-size: 100% 100%;
      padding-top: 0.64rem;
      .part-title {
        margin-bottom: 0.21rem;
      }
      .part2-title {
        font-size: 0.19rem;
        margin-bottom: 0.46rem;
      }
      .p3_main {
        width: 6.13rem;
        height: 6.37rem;
        border-radius: 0.11rem;
        flex-direction: row;
        .p3_mUp {
          width: 1.73rem;
          height: 100%;
          border-bottom: 0px;
          border-right: 1px solid #d7d7d7;
          padding: 0px 0px;
          flex-direction: column;
          .p3_mUp-line {
            display: none;
          }
          .p3_uItem {
            flex: 1;
            width: 100%;
            padding: 0px 0px;
            border-bottom: 1px solid #d7d7d7;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            span {
              font-size: 0.17rem;
              line-height: 0.35rem;
            }
          }
          .p3_uItem:last-child {
            border-bottom: 0px;
          }
        }
        .p3_mContent {
          box-sizing: border-box;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-left: 0px;
          margin-right: 0px;
          padding-top: 0.23rem;
          padding-left: 0.29rem;
          padding-right: 21px;
          .p3_mCLeft {
            width: 3.84rem;
            padding-top: 0px;
            margin-bottom: 0.01rem;
            > span {
              font-size: 0.21rem;
              margin-bottom: 0.17rem;
            }
            > p {
              font-size: 0.17rem;
              line-height: 0.27rem;
              margin-bottom: 0.3rem;
              display: -webkit-box;
              text-overflow: ellipsis;
              overflow: hidden;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
            }
            > div {
              width: 3rem !important;
              margin-bottom: 0px;
              span {
                width: 1.38rem;
                height: 0.37rem;
                border-radius: 0.04rem;
                line-height: 0.37rem;
                font-size: 0.17rem;
                margin-bottom: 0.16rem;
              }
            }
            > a {
              display: none;
            }
          }
          .p3_mCRight {
            width: 2.53rem;
            height: 1.94rem;
            padding-top: 0px;
            img {
              width: 2.53rem;
              height: 1.94rem;
            }
          }
          > .p3_mDown {
            display: block;
          }
          > a {
            display: block;
            font-size: 0.17rem;
            font-family: FontExcellent;
            font-weight: 400;
            color: #005cb9;
            margin-left: auto;
            margin-top: 0.04rem;
          }
        }
        .p3_mDown {
          box-sizing: border-box;
          flex: none;
          display: flex;
          flex-direction: row;
          border-top: 0px;
          padding-top: 0px;
          padding-left: 0px;
          margin-left: -0.13rem;
          img {
            width: 1.2rem;
            height: auto;
            margin-right: 0px;
          }
        }
        > .p3_mDown {
          display: none;
        }
      }
      .p3_down {
        height: 1.79rem;
        margin-top: -0.25rem;
        padding-top: 0rem;
        .p3_dMain {
          width: 6.1rem;
          display: block;
          .swiper-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          .swiper-item:nth-of-type(2) {
            justify-content: flex-start;
            .p3_dItem {
              margin-right: 0.5rem;
            }
          }
          .p3_dItem {
            div {
              width: 0.25rem;
              height: 0.25rem;
              margin-right: 0.13rem;
              img {
                width: 0.25rem;
              }
            }
            span {
              font-size: 0.19rem;
            }
          }
        }
        .p3_dMain_pc {
          display: none;
        }
      }
    }
    .part4 {
      padding-top: 0.45rem;
      .part-title {
        margin-bottom: 0.56rem;
      }
      .p4_main {
        width: 6.15rem;
        .p4_left {
          width: 2.92rem;
          height: 3.45rem;
          padding-top: 0.18rem;
          .p4_lUp {
            height: 1.63rem;
          }
          .p4_lDown {
            padding: 0px 0.18rem;
            padding-top: 0.15rem;
            > span {
              font-size: 0.19rem;
              margin-bottom: 0.1rem;
            }
            > p {
              width: 100%;
              font-size: 0.17rem;
              line-height: 0.27rem;
              -webkit-line-clamp: 2;
              margin-bottom: 0.17rem;
            }
            > div {
              width: 2.54rem;
              span {
                font-size: 0.17rem;
                color: #333333;
              }
              p {
                font-size: 0.13rem;
              }
            }
          }
        }
        .p4_left:hover {
          span {
            color: #0b62bb;
          }
          .p4_lDown {
            div {
              span {
                color: #0b62bb;
              }
            }
          }
        }
        .p4_right {
          width: 3.02rem;
          li {
            flex: none;
            box-sizing: border-box;
            width: 100%;
            height: 1.71rem;
            padding-left: 0.44rem;
            padding-top: 0.18rem;
            a {
              > span {
                width: 2.46rem;
                font-size: 0.19rem;
                margin-bottom: 0.11rem;
                -webkit-line-clamp: 1;
              }
              > p {
                font-size: 0.17rem;
                -webkit-line-clamp: 2;
                line-height: 0.28rem;
                width: 2.46rem;
                margin-bottom: 0.17rem;
              }
              > div {
                width: 2.46rem;
                span {
                  font-size: 0.17rem;
                }
                p {
                  font-size: 0.13rem;
                }
              }
            }
          }
          li::after {
            top: 0.23rem;
            left: 0.14rem;
            width: 0.14rem;
            height: 0.14rem;
          }
          li:last-child {
            display: none;
          }
          li:nth-of-type(2) {
            border-bottom: 1px solid #dbdbdb;
          }
        }
      }
      .p4_main:nth-of-type(2) {
        display: flex;
        margin-top: 0.18rem;
      }
      .p4_down {
        width: 1.89rem;
        height: 0.54rem;
        background: #005cb9;
        border-radius: 0.07rem;
        font-size: 0.19rem;
        line-height: 0.54rem;
        margin-top: 0.59rem;
      }
    }
    .part5 {
      width: 100%;
      padding-top: 0.8rem;
      .part-title {
        margin-bottom: 0.44rem;
      }
      p {
        width: 6.1rem;
        font-size: 0.19rem;
        line-height: 0.3rem;
        margin-bottom: 0.3rem;
      }
      p:nth-of-type(3),
      p:nth-of-type(4),
      p:nth-of-type(5) {
        display: none;
      }
      > a {
        display: block;
        width: 1.89rem;
        margin: 0 auto;
        height: 0.54rem;
        background: #005cb9;
        border-radius: 0.07rem;
        font-size: 0.19rem;
        font-family: FontExcellent;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 0.54rem;
        margin-top: 0.48rem;
      }
      .p5_video {
        width: 100%;
        background: linear-gradient(
          -38deg,
          #ffffff 0%,
          #f2f4fa 52%,
          #ffffff 100%
        );
        margin-top: 0.45rem;
        img {
          display: block;
          width: 6.14rem;
          height: 3.44rem;
          margin: 0 auto;
        }
      }
    }
    .part6 {
      padding-top: 0.8rem;
      .part-title {
        margin-bottom: 0.4rem;
      }
      .part6_main {
        width: 6.6rem;
        .part6-title {
          font-size: 0.24rem;
          margin-bottom: 0.4rem;
        }
        .swiper-item {
          height: 3.01rem;
          .p6-item {
            width: 2.8rem;
            height: 3.01rem;
            background: linear-gradient(
              -38deg,
              #ffffff 0%,
              #f2f4fa 52%,
              #ffffff 100%
            );
            > div {
              height: 2.5rem;
              img {
                max-width: 75%;
                max-height: 85%;
                z-index: 3;
              }
            }
            span {
              width: 100%;
              height: 100%;
              background: url('../assets/img/about/about/part4-item-bg.png')
                no-repeat;
              background-size: 100% 100%;
              p {
                height: 0.65rem;
                font-size: 0.16rem;
                padding: 0px 0.25rem;
              }
            }
          }
        }
        .part6_up {
          margin-bottom: 0.48rem;
        }
        .part6_down {
          margin-bottom: 0.61rem;
        }
      }
      .p6_pc {
        display: none;
      }
      .p6_mobile {
        display: block;
      }
    }
    .part7 {
      height: 5.73rem;
      .p7_img_pc {
        display: none;
      }
      .p7_img_mobile {
        display: block;
      }
      .p7_main {
        span {
          width: 1.9rem;
          font-size: 0.25rem;
          margin-bottom: 0.27rem;
        }
        a {
          width: 1.9rem;
          height: 0.51rem;
          border: 0.03rem solid #ffffff;
          border-radius: 0.04rem;
          font-size: 0.25rem;
          line-height: 0.51rem;
        }
      }
    }
  }
}
</style>
<style lang="less">
.index {
  .p1-swiper {
    .swiper-container {
      height: 700px;
      .p1_item {
        width: 1200px;
        margin: 0 auto;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .index {
    .p1-swiper {
      .swiper-container {
        height: 4.12rem;
        .swiper-slide {
          width: auto;
        }
        .p1_item {
          width: 6.1rem;
        }
      }
      .p1_down-2 {
        .swiper-container {
          height: 1.7rem;
        }
      }
    }
    .p3_dMain_mobile .swiper-pagination {
      width: 6.1rem;
      margin-top: 0.32rem;
      .swiper-pagination-bullet {
        width: 0.13rem;
        height: 0.13rem;
        background: #ffffff;
        border-radius: 50%;
        margin: 0rem 0.08rem;
        opacity: 1;
        transition: width 0.5s;
      }
      .swiper-pagination-bullet-active {
        width: 0.49rem;
        border-radius: 0.07rem;
      }
    }
    .el-carousel__container {
      height: 1.73rem;
    }
    .el-carousel__indicators--horizontal {
      bottom: 0.05rem;
      .el-carousel__button {
        width: 0.13rem;
        height: 0.13rem;
        background: #ffffff;
        border-radius: 50%;
        margin: 0rem 0.08rem;
        opacity: 1;
        transition: width 0.5s;
      }
      .el-carousel__indicator.is-active button {
        width: 0.49rem;
        border-radius: 0.07rem;
      }
    }
  }
}
</style>
